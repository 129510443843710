import environment from "@/environment";
const { permissions } = environment;

export default [
  {
    title: 'menu.home',
    icon: 'mdi-book-open-outline',
    name: 'Dashboard',
  },

  {
    title: 'menu.users',
    icon: 'mdi-card-account-details-outline',
    name: 'Professionals',
  },

  {
    title: 'menu.empresas',
    icon: 'mdi-home-city-outline',
    name: 'Empresas',
  },

  {
    title: 'menu.productos',
    icon: 'mdi-widgets-outline',
    name: 'Productos',
  },

  {
    title: 'menu.patients',
    icon: 'mdi-account-multiple-outline',
    name: 'Pacientes',
  },

  {
    title: 'menu.prescriptionsFilteredList',
    icon: 'mdi-clipboard-edit-outline',
    name: 'prescriptionsFilteredList',
  },

  {
    title: 'menu.messaging',
    icon: 'mdi-comment-text-multiple-outline',
    name: 'Mensajeria',
  },

  {
    title: 'menu.videocam',
    icon: 'mdi-video-wireless-outline',
    name: 'Videoconsulta',
  },

  ...((!permissions.global.newSchedulePage) ? [
    {
      title: 'menu.calendario',
      icon: 'mdi-calendar-month-outline',
      name: 'CalendarioEspecialidad',
    }
  ] : []),

  {
    title: 'menu.packcontratados',
    icon: 'mdi-image-filter-none',
    name: 'Packscontratados',
  },

  {
    title: 'suscripciones.suscripcion_contratados',
    icon: 'mdi-autorenew',
    name: 'Suscripcionescontratadas',
  },

  {
    title: 'menu.resenas',
    icon: 'mdi-message-draw',
    name: 'Resenas',
  },

  {
    title: 'menu.servicios',
    icon: 'mdi-currency-usd',
    name: 'configServicios',
  },

  {
    title: 'menu.dispositivos',
    icon: 'mdi-cellphone-link',
    name: 'Dispositivos',
  },

  {
    title: 'menu.facturacion',
    icon: 'mdi-script-text-outline',
    name: 'Facturacion',
  },

  {
    title: 'menu.reports',
    icon: 'mdi-text-box-search-outline',
    name: 'Reportes',
  },

  {
    title: 'menu.myMessages',
    icon: 'mdi-comment-text-multiple-outline',
    name: 'myMessages',
  },

  {
    title: 'menu.myVideoconsultations',
    icon: 'mdi-video-wireless-outline',
    name: 'myVideoconsultations',
  },

  {
    title: 'menu.myPrescriptions',
    icon: 'mdi-text-box-check-outline',
    name: 'myPrescriptions',
  },

  {
    title: 'menu.mySubscriptions',
    icon: 'mdi-autorenew',
    name: 'mySubscriptions',
  },

  {
    title: 'menu.myDocuments',
    icon: 'mdi-text-box-search-outline',
    name: 'myDocuments',
  },

  {
    title: 'menu.medicalTests',
    icon: 'mdi-file-document-outline',
    name: 'moduleMedicalTests',
    group: 'moduleMedicalTests',
    items: [
      {
        title: 'menu.medicalTestTypes',
        name: 'medicalTestsTypes',
      },
      {
        title: 'menu.medicalTestRxProtocols',
        name: 'rxProtocols',
      },
      {
        title: 'menu.medicalTestNomenclator',
        name: 'medicalTestsNomenclator',
      },
    ],
  },

  ...((permissions.global.newSchedulePage) ? [
    {
      title: 'menu.agenda',
      icon: 'mdi-calendar-text',
      name: 'CalendarioEspecialidad',
      href: `${environment.portalBoard}/schedule`,
    },
  ] : []),

  {
    divider: true,
  },

  {
    title: 'menu.settings',
    group: 'ajustes',
    name: 'ajustes',
    icon: 'mdi-cog-outline',
    items: [
      {
        title: 'menu.talonario',
        name: 'ajustes/talonario',
      },

      {
        title: 'menu.firma',
        name: 'ajustes/firma',
      },

      {
        title: 'menu.tarifas',
        name: 'ajustes/videoconsultas',
      },

      {
        title: 'menu.packs',
        name: 'ajustes/packs',
      },

      {
        title: 'menu.agenda',
        name: 'ajustes/agenda',
      },

      {
        title: 'menu.cupones',
        name: 'ajustes/cupones',
      },

      {
        title: 'menu.miweb',
        name: 'ajustes/landing',
      },

      {
        title: 'menu.recepcionista',
        name: 'ajustes/recepcionista',
      },

      {
        title: 'menu.companyConfig',
        name: 'ajustes/company-config',
      },

      {
        title: 'user.especialidades',
        name: 'ajustes/especialidades',
      },

      {
        title: 'menu.servicios',
        name: 'ajustes/servicios',
      },

      {
        title: 'agenda.nodisponible',
        name: 'ajustes/diasnodisponibles',
      },

      {
        title: 'agenda.disponible',
        name: 'ajustes/diasdisponibles',
      },

      {
        title: 'common.configuracion',
        name: 'ajustes/valorespersonalizados',
      },

      {
        title: 'menu.beneficiaries',
        name: 'settings/beneficiaries',
      },
    ],
  },

  {
    title: 'menu.help',
    icon: 'mdi-help-circle-outline',
    name: 'Ayuda',
  },

  {
    title: 'menu.contact',
    icon: 'mdi-comment-question-outline',
    name: 'Contacto',
  },
];
